import Navigo from 'navigo';
import './vendor/particles.min';
import './vendor/hammer-2.0.8';
import './3dObjects';
import VanillaTilt from "vanilla-tilt";
import Swiper, { EffectCoverflow, Mousewheel, Autoplay, Navigation, Pagination, Keyboard } from 'swiper';// HashNavigation, History,
import MicroModal from 'micromodal';
import Cookies from 'js-cookie'

const ROUTE_HOME = 'home', ROUTE_WORKS = 'works', ROUTE_CONTACTS = 'contacts', ROUTE_HIRE_US = 'hire-us';
const ROUTES_ARR = [ROUTE_HOME, ROUTE_WORKS, ROUTE_CONTACTS, ROUTE_HIRE_US];

$( document ).ready(function() {
  let swiper,
      curPage = -1,
      initialSlide = 0,
      curModalId = 0,
      modalIsOpen = false,
      canScroll = true,
      scrollController = null;

  /**
   * LANG
   */
  const onLangClick = e => {
    Cookies.set('localization', e.target.className.split('-')[1])
    location.reload();
  }
  $('.lng-cnt').on( "click", "a:not(.is-active)", onLangClick );

  /**
   * MODAL
   */
  const closeModal = (e) => {
    if (!e.target.hasAttribute('data-modal-close')) return;
    router.navigate(ROUTE_WORKS+'/'+router.current[0].data.id);
  }

  const getUrls = urls => {
    let line = '';
    urls.forEach(url => {
      if (url.includes('apps.apple')){
        line = line + '<a href="'+url+'" target="_blank"  class="appstore animated-hover"><img src="/media/appstore.png" alt=""></a>';
      }else if (url.includes('play.google')){
        line = line + '<a href="'+url+'" target="_blank"  class="googleplay animated-hover"><img src="/media/google.png" alt=""></a>';
      }else{
        line = line + '<a href="'+url+'" target="_blank" class="projecturl animated-hover">'+url.split('://')[1]+'</a>'
      }
    });
    if (line === ''){
      line = '<span class="separator_line just_line"></span>';
    }
    return line;
  }

  const getImages = imgs => {
    let line = '';
    imgs.forEach(url => {
      line = line + '<img src='+url+' alt="">';
    });
    return line;
  }

  const onModalShow = modal => {
    let modal2 = modal.cloneNode();
    const projData = projects[initialSlide] ? projects[initialSlide].data : projects[0].data;
    $(modal2).html(
      '<div class="modal__overlay" tabindex="-1" data-modal-close>' +
        '<button class="modal__close animated-hover" data-modal-close="" aria-label="Close project details window"></button>' +
        '<div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="project-details-title">' +
          '<header class="modal__header">'+
            '<h2 class="modal__title separator_line" id="project-details-title">'+projData.title+'</h2>'+
            '<div class="proj_tec_list"><span>'+ projData.tags.replaceAll(',', '</span><span>') +'</span></div>'+
          '</header>'+
          '<main class="modal__content" id="project-details-content">'+
            '<div class="header_'+projData.header.type+'" >'+(projData.header.type === 'youtube' ? '<iframe src="'+projData.header.url+'" title="YouTube video player" frameborder="0" rel=0 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' : '<img src='+projData.header.url+' alt="">')+'</div>'+
            '<p>'+ projData.description +'</p>' +
            '<div class="project_urls_cnt">'+getUrls(projData.urls)+'</div>' +
            '<div class="project_imgs_cnt">'+getImages(projData.images)+'</div>' +
          '</main>' +
          '<footer class="modal__footer">' +
          '</footer>' +
        '</div>' +
        '<a href="/'+ROUTE_WORKS+'/slide'+(initialSlide-1).toString()+'/open" data-navigo class="projecturl animated-hover modal__arrow modal__arrow__prev" aria-label="Open previous project details"></a>' +
        '<a href="/'+ROUTE_WORKS+'/slide'+(initialSlide+1).toString()+'/open" data-navigo class="projecturl animated-hover modal__arrow modal__arrow__next" aria-label="Open next project details"></a>' +
      '</div>'
    );//'+(projData.header.type === 'img' ? 'style="background: url('+projData.header.url+') no-repeat center center; background-size: cover;"' : '')+'
    modal.replaceWith(modal2);
    canScroll = false;
    $(modal2).on( "click", "[data-modal-close]", closeModal );
  }

  const clearModalData = modal => {
    let modal2 = modal.cloneNode();
    $(modal2).html('');
    modal.replaceWith(modal2);
  }

  const onModalClose = modal => {
    canScroll = true;
    $(modal).off( "click", "[data-modal-close]", closeModal );
    setTimeout(clearModalData, 1000, modal);
  }

  const modalConfig = {
    onShow: onModalShow, // [1]
    onClose: onModalClose, // [2]
    // openTrigger: 'data-custom-open', // [3]
    // closeTrigger: 'data-custom-close', // [4]
    // openClass: 'is-open', // [5]
    disableScroll: true, // [6]
    disableFocus: false, // [7]
    awaitOpenAnimation: false, // [8]
    awaitCloseAnimation: true, // [9]
    // debugMode: true // [10]
  };

  /**
   * CALC degree of side menu
   */
  const resize = () => {
    let deg = (1050/$( window ).width()*($('.outer-nav').width()/6.4));
    $(".container").attr("style",`--degreeA: ${deg > 66 ? 66 : deg}deg; --itemHeight: ${$('.work .showcase-carousel--item').width()/320*250}px;`);

    const min = 338, max = 960, minP = 1200, maxP = 2300;
    let swiper = $('.swiper-3d');
    swiper.attr("style", `perspective: ${Math.round(maxP - (swiper.width() - min) / (max - min) * (maxP - minP))}px;`);
  }
  $( window ).resize(resize);
  resize();

  /**
   * NAVIGATION
   */
  const router = new Navigo('/');
  const navParser = ({url, data, route}) => {
    let firstRoute = url.split('/')[0];
    let nextIdx = ROUTES_ARR.findIndex(element => element === firstRoute);

    /** SLIDES */
    if (data && data.id){
      initialSlide = Number(data.id.replace('slide', ''));
      if (swiper && swiper.realIndex !== initialSlide)
        swiper.slideToLoop(initialSlide, 500);
    }else if (ROUTES_ARR[nextIdx] === ROUTE_WORKS && swiper){
      initialSlide = 0;
      swiper.slideToLoop(initialSlide, 500);
    }

    /** MODAL */
    if (data && data.action){
      MicroModal.show('project-details', modalConfig);
      curModalId = initialSlide;
      modalIsOpen = true;
    }else if (modalIsOpen){
      modalIsOpen = false;
      MicroModal.close('project-details');
    }

    /** PAGES */
    if (nextIdx === -1) nextIdx = 0;
    if (nextIdx !== curPage) {
      if (firstRoute === ROUTE_WORKS && swiper){
        swiper.enable();
      }else if (swiper && swiper.enabled){
        swiper.disable();
      }
      updateNavs(nextIdx);
      updateContent(curPage, nextIdx, ROUTES_ARR.length - 1);

      curPage = nextIdx;
    }
  }

  const nav404 = () => {
    router.navigate(ROUTES_ARR[0]);
  }

  router.on(ROUTE_HOME, navParser);
  router.on(ROUTE_WORKS, navParser);
  router.on(ROUTE_WORKS+'/:id', navParser);
  router.on(ROUTE_WORKS+'/:id/:action', navParser);
  router.on(ROUTE_CONTACTS, navParser);
  router.on(ROUTE_HIRE_US, navParser);
  router.notFound(nav404);
  router.resolve();

  const routeTo = pageIdx => {
    if (ROUTES_ARR[pageIdx] && pageIdx !== curPage)
    router.navigate(ROUTES_ARR[pageIdx]);
  }

  /**
   * EFFECT 3D title
   */
  VanillaTilt.init(document.querySelectorAll('.tilt'), {speed: 1000, max: 8,  gyroscope: true});

  /**
   * EFFECT particles bg
   */
  particlesJS('particles-js', {"particles":{"number":{"value":50,"density":{"enable":true,"value_area":800}},"color":{"value":"#ffffff"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":5}},"opacity":{"value":0.2,"random":false,"anim":{"enable":false,"speed":1,"opacity_min":0.1,"sync":false}},"size":{"value":3,"random":true,"anim":{"enable":false,"speed":40,"size_min":0.1,"sync":false}},"line_linked":{"enable":true,"distance":150,"color":"#ffffff","opacity":0.2,"width":1},"move":{"enable":true,"speed":3,"direction":"none","random":false,"straight":false,"out_mode":"out","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}},"interactivity":{"detect_on":"window","events":{"onhover":{"enable":true,"mode":"grab"},"onclick":{"enable":true,"mode":"grab"},"resize":true},"modes":{"grab":{"distance":200,"line_linked":{"opacity":0.2}},"bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},"repulse":{"distance":200,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true});//push

  /**
   * DOMMouseScroll included for firefox support
   */
  $(this).on('mousewheel DOMMouseScroll', function(e){

    if (!($('.outer-nav').hasClass('is-vis'))) {

      e.preventDefault();

      let delta = (e.originalEvent.wheelDelta) ? -e.originalEvent.wheelDelta : e.originalEvent.detail * 20;

      if ((delta > 50 || delta < -50) && canScroll){
        canScroll = false;
        clearTimeout(scrollController);
        scrollController = setTimeout(function(){
          canScroll = true;
        }, 800);
        updateHelper(delta < 0 ? -1 : 1);
      }
    }
  });

  // swipe support for touch devices
  let targetElement = document.getElementById('viewport'),
      mc = new Hammer(targetElement);
  mc.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
  mc.on('swipeup swipedown', function(e) {

    updateHelper(e);

  });

  $(document).keyup(function(e){

    if (!($('.outer-nav').hasClass('is-vis')) && (e.keyCode === 40 || e.keyCode === 38)) {
      e.preventDefault();
      updateHelper(e);
    }

  });

  /**
   * determine scroll, swipe, and arrow key direction
   */
  function updateHelper(param) {

    let curActive = $('.side-nav').find('.is-active'),
        curPos = $('.side-nav').children().index(curActive),
        lastItem = $('.side-nav').children().length - 1,
        nextPos = 0;

    if (param.type === "swipeup" || param.keyCode === 40 || param > 0) {
      if (curPos !== lastItem) {
        nextPos = curPos + 1;
      }
    } else if (param.type === "swipedown" || param.keyCode === 38 || param < 0){
      nextPos = curPos !== 0 ? curPos - 1 : lastItem;
    }

    routeTo(nextPos);
  }

  /**
   * sync side and outer navigations
   */
  function updateNavs(nextPos) {

    $('.side-nav, .outer-nav').children().removeClass('is-active');
    $('.side-nav').children().eq(nextPos).addClass('is-active');
    $('.outer-nav').children().eq(nextPos).addClass('is-active');

  }

  /**
   * update main content area
   */
  function updateContent(curPos, nextPos, lastItem) {
    $('.main-content').children().removeClass('section--is-active');
    $('.main-content').children().eq(nextPos).addClass('section--is-active');
    $('.main-content .section').children().removeClass('section--next section--prev');

    if (curPos === lastItem && nextPos === 0 || curPos === 0 && nextPos === lastItem) {
      $('.main-content .section').children().removeClass('section--next section--prev');
    }else if (curPos !== -1){
      if (curPos < nextPos) {
        $('.main-content').children().eq(curPos).children().addClass('section--next');
      }
      else {
        $('.main-content').children().eq(curPos).children().addClass('section--prev');
      }
    }

    if (nextPos !== 0 && nextPos !== lastItem) {
      $('.header--cta').addClass('is-active');
    }
    else {
      $('.header--cta').removeClass('is-active');
    }

    setTimeout(closeOuterNav, 400);
  }

  /**
   * OUTER NAV
   */
  function openOuterNav() {
    $('.header--nav-toggle').addClass("open");

    $('.perspective').addClass('perspective--modalview');
    setTimeout(function(){
      $('.perspective').addClass('effect-rotate-left--animate');
    }, 25);
    $('.outer-nav, .outer-nav li, .outer-nav--return').addClass('is-vis');
  }

  function closeOuterNav() {
    if (!$('.header--nav-toggle').hasClass('open')) return;
    $('.header--nav-toggle').removeClass("open");

    $('.outer-nav, .outer-nav li, .outer-nav--return').removeClass('is-vis');
    setTimeout(function(){
      $('.perspective').removeClass('effect-rotate-left--animate');
    }, 125);
    setTimeout(function(){
      $('.perspective').removeClass('perspective--modalview');
    }, 525);
  }

  const outerNav = () => {
    $('.header--nav-toggle').click(openOuterNav);

    $('.outer-nav--return, .outer-nav li').click(closeOuterNav);
  }

  /**
   * SLIDER
   */
  function onActiveSlide() {
    for (let index = 0; index < this.slides.length; index++)
      this.slides[index].getElementsByTagName('a')[0].style.display = 'none';

    this.slides[ this.activeIndex ].getElementsByTagName('a')[0].style.display = 'block';

    if (ROUTES_ARR[curPage] === ROUTE_WORKS && initialSlide !== this.realIndex)
      router.navigate(ROUTE_WORKS+'/slide'+this.realIndex.toString()+(modalIsOpen ? '/open' : '/'));
  }

  function workSlider() {
    Swiper.use([EffectCoverflow, Mousewheel, Navigation, Pagination, Keyboard, Autoplay]);//HashNavigation,History,
    swiper = new Swiper('.showcase-carousel', {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 3,
      speed: 1200,
      direction: 'horizontal',
      perspective: true,
      slideToClickedSlide: true,
      centeredSlidesBounds: true,
      // hashNavigation:true,
      // history:{
      //   replaceState: true,
      //   root:'/',
      //   key: "works",
      // },
      coverflowEffect: {
        rotate: 29,
        stretch: 9,
        depth: 200,
        modifier: 1.5,
        scale: .9,
        slideShadows: true,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      mousewheel: true,
      keyboard: true,
      enabled: false,
      on: { slideChange: onActiveSlide, init: resize },
      breakpointsBase: 'container',
      initialSlide: initialSlide,
    });

    if (ROUTES_ARR[curPage] === ROUTE_WORKS) swiper.enable();
  }

  function transitionLabels() {

    $('.work-request--information input').focusout(function(){

      let textVal = $(this).val();

      if (textVal === "") {
        $(this).removeClass('has-value');
      }
      else {
        $(this).addClass('has-value');
      }

      // correct mobile device window position
      window.scrollTo(0, 0);

    });

  }

  outerNav();
  workSlider();
  transitionLabels();

});
