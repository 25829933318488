import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {BufferGeometryUtils} from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import matcapDark from '../img/effect_fire_hellsbreath.jpg';
import matcapSoul from '../img/soul.jpg';
import fragment from "./shader/fragment.glsl";
import vertex from "./shader/vertex.glsl";
import plus from '../models/plus.glb'

export default class Sketch {
  constructor(options) {
    this.scene = new THREE.Scene();



    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance",
      alpha: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    //this.renderer.setClearColor(0x000000, 1);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.getData()



    // Instantiate a loader
    this.loader = new GLTFLoader();



    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );


    this.camera.position.set(0, 1, 2);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableZoom = false;
    this.time = 0;

    this.isPlaying = true;

    // this.loader.load(plus,(gltf)=>{

      this.addObjects();
      this.resize();
      this.render();
      this.setupResize();
      this.settings()



      // gltf.scene.traverse(o=>{
      //   if(o.isMesh){
      //     this.plus = o;
      //     console.log(o);
      //   }
      // })

      this.createShapes()

      this.switchTo(this.DOMshape)
      this.switchMatcap(this.DOMcolor);

    // })



  }

  getData(){
    this.DOMspeed = .5;
    this.DOMshape = 'icosahedron';
    this.DOMdistortion = '5';
    this.DOMcolor = 'dark';
    this.showSettings = false;
  }

  createShapes(){
    this.shapes =
    {
      icosahedron:{
        title: "icosahedron",
        geometry: new THREE.IcosahedronBufferGeometry(1,1),
        axis: new THREE.Vector3(1.,0,0.),
        flat: true,
        // material:
      },
    }
  }


  switchTo(shape){
    if(this.shape) this.scene.remove(this.shape);
    // create obj, from this.shapes, and settings
    this.geometry = this.shapes[shape].geometry;
    this.shape = new THREE.Mesh(this.geometry, this.material);
    this.material.uniforms.axis.value = this.shapes[shape].axis;
    if(this.shapes[shape].axis2){
      this.material.uniforms.axis2.value = this.shapes[shape].axis2;
    } else{
      this.material.uniforms.axis2.value = this.shapes[shape].axis;
    }
    this.material.uniforms.axis.value = this.shapes[shape].axis;
    this.material.uniforms.flatNormals.value = this.shapes[shape].flat?1:0;

    // reset settings
    this.settings.progress = 0;
    // this.shape.rotation.y = 10;
    this.scene.add(this.shape)

    this.matcaps = {
      "dark": new THREE.TextureLoader().load(matcapDark),
    }
  }

  switchMatcap(name){
    this.material.uniforms.matcaptexture.value = this.matcaps[name]
  }

  settings() {
    this.settings = {
      progress: 0,
      distortion: parseFloat(this.DOMdistortion),
      shape: this.DOMshape,
      color: this.DOMcolor,
      speed: parseFloat(this.DOMspeed),
    };
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    let that = this;
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        progress: { value: 0 },
        flatNormals: { value: 0 },
        distortion: { value: 0 },
        speed: { value: 1 },
        axis: { value: null },
        axis2: { value: null },
        resolution: { value: new THREE.Vector4() },
        matcaptexture: { value: new THREE.TextureLoader().load(matcapSoul) },
      },
      // wireframe: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });

  }

  Sphube(u1,v1,target){
    // https://arxiv.org/pdf/1604.02174.pdf
    let s = 0.6;
    let r = 1;
    let theta = 2*u1*Math.PI;
    let phi = v1*2*Math.PI ;

    let u = Math.cos(theta)*Math.cos(phi);
    let v = Math.cos(theta)*Math.sin(phi);
    let w = Math.sin(theta)

    let z = r*u/Math.sqrt(1 - s*v**2 - s*w**2 )
    let x = r*v/Math.sqrt(1 - s*u**2 - s*w**2 )
    let y = r*w/Math.sqrt(1 - s*Math.cos(theta)**2)

    target.set( x, y, z );
  }

  stop() {
    this.isPlaying = false;
  }

  play() {
    if(!this.isPlaying){
      this.render()
      this.isPlaying = true;
    }
  }

  render() {
    if (!this.isPlaying) return;
    this.time += 0.01;
    // if(this.shape) this.shape.rotation.y = this.time;
    // this.plane.rotation.y= this.time/2;
    this.material.uniforms.time.value = this.time;
    this.material.uniforms.progress.value = this.settings.progress;
    this.material.uniforms.speed.value = this.settings.speed;
    this.material.uniforms.distortion.value = this.settings.distortion;
    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
  }
}

new Sketch({
  dom: document.getElementById("animBox")
});
